.playButton {
  background: transparent;
  position: relative;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.playIcon {
  width: 100%;
  height: 100%;
}
