.albumCard {
  padding: 2%;
  display: grid;
  grid-template-areas:
    "cover-image header edit"
    "content content content";
  grid-column-gap: 20px;
  grid-template-columns: auto 1fr auto;
  grid-auto-rows: minmax(0, auto);
  font-size: 16px;
}

.EditSvg {
  grid-area: edit;
  align-self: self-start;
  justify-self: self-start;
  cursor: pointer;
  width: 40px;
}

.coverImage {
  grid-area: cover-image;
  max-width: 250px;
}

.coverImage img {
  max-width: 100%;
  border-radius: 8px;
}

.albumHeader {
  grid-area: header;
  display: grid;
  grid-template-areas:
    "title"
    "intro";
}

.addAlbumToPlalistButton {
  align-self: self-start;
  max-width: 200px;
  width: 9em;
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 10px;
  border: none;
  color: var(--main-white);
  background-color: var(--primary-bg-color);
  justify-items: center;
  align-items: center;
  border-radius: 100px;
}

.addAlbumToPlalistButton:hover {
  background-color: var(--button-hover-bg-color);
}

.addAlbumToPlalistButton div {
  font-size: 18px;
  justify-self: self-start;
}

.addAlbumToPlalistButton img {
  justify-self: self-start;
  align-self: self-start;
  max-width: 60px;
  min-width: 50px;
}

.albumName {
  /* color: var(--green); */
  grid-area: title;
  display: block;
  font-size: 1.5em;
  margin-bottom: 8px;
  align-self: self-end;
}

.intro {
  grid-area: intro;
  font-size: 1.2em;
  margin-bottom: 16px;
}

.albumContent {
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  row-gap: 10px;
}

.albumArtistTagContainer {
  display: grid;
  row-gap: 10px;
}

.songList {
  margin-bottom: 5px;
  display: grid;
}

@media (min-width: 769px) and (max-width: 992px) {
  .albumCard {
    grid-template-columns: minmax(15%, 20%) 1fr;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .albumCard {
    grid-template-columns: minmax(0, 20%) 1fr;
  }
}

@media (max-width: 576px) {
  .albumCard {
    grid-template-areas:
      "cover-image header edit"
      "content content content";
    grid-template-columns: 30% 1fr;
    align-items: center;
  }

  .intro {
    display: none;
  }

  .albumContent {
    grid-area: content;
  }
}
