.searchInput {
  background-color: var(--black-main);
  color: var(--white-main);
  border: none;
  outline: none;
  border-radius: 10px;
  padding-left: 10px;
  width: 100%;
  height: 100%;
}
