.albumPreview {
  display: grid;
  grid-template-areas:
    "cover cover"
    "name play"
    "artists play"
    "tags play";
  position: relative;
  align-items: center;
  row-gap: 10px;
  padding: 16px;
  border-radius: 8px;
  background: #181818;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  transition-duration: 0.3s;
  transition-delay: 0s;
}

.albumPreview:hover {
  background-color: var(--button-hover-bg-color);
  grid-template-columns: 1fr minmax(0, 28%);
}

.coverImageContainer {
  grid-area: cover;
  border-radius: 6px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  /* Создаем квадратный контейнер с пропорцией 1:1 */
  overflow: hidden;
}

.coverImage {
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  /* Заполняем контейнер, сохраняя пропорции и обрезая избыточные части изображения */
  object-position: center bottom;
  /* Центрируем по горизонтали и прижимаем к нижней части контейнера */
  border-radius: 4px;
}

.playIcon {
  grid-area: play;
  display: none;
  transition: opacity 0.3s ease;
  position: relative;
  object-fit: cover;
}

.albumPreview:hover .playIcon {
  display: block;
}

.albumName {
  grid-area: name;
}

.artists {
  grid-area: artists;
}

.tags {
  grid-area: tags;
}

.albumName,
.artists,
.tags {
  overflow: hidden;
  white-space: nowrap;
  /* Запрещаем перенос строки */
  text-overflow: ellipsis;
  /* Добавляем многоточие, если текст не помещается */
}

@media (max-width: 576px) {
  .albumPreview {
    padding: 8px;
    grid-template-areas:
      "cover name play"
      "cover artists play"
      "cover tags play";
    grid-template-columns: 22% 1fr 17%;
    column-gap: 5%;
  }

  .albumPreview:hover {
    grid-template-columns: 22% 1fr 17%;
  }

  .coverImageContainer {
    height: auto;
  }

  .albumName {
    align-self: self-end;
  }

  .playIcon {
    min-width: 50px;
    display: block;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
  }

  .artists {
    align-self: self-start;
  }
}
