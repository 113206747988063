.svgButton {
  position: relative;
  display: grid;
  height: 100%;
  width: 100%;
}

.svgButton img {
  position: relative;
  width: 100%;
}
