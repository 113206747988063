.homePage {
  background-color: var(--black-bg-color);
  min-height: 100%;
}

.albumList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 18px;
  padding: 20px;
}

.paginationContainer {
  position: relative;
  height: 50px;
}

@media (max-width: 576px) {
  .albumList {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px;
  }
}
