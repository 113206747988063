.queuePage {
  min-height: 100%;
  background-color: rgba(40, 40, 40, 0.95);
  padding: 15px;
}

.queuePage h1 {
  margin-top: 0px;
}

.queueListContainer {
  display: grid;
  grid-template-areas:
    "next delete"
    "list list";
  grid-template-columns: 1fr 1fr;
}

.next {
  grid-area: next;
}

.deleteButton {
  cursor: pointer;
  grid-area: delete;
  align-self: center;
  justify-self: self-end;
}

.queueList {
  grid-area: list;
}
