.bm-overlay {
  /* display: none; */
  position: absolute;
  top: 0;
  right: 0;
  width: 1vh;
  z-index: 10000;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  /* position: fixed; */
  width: 76px;
  height: 36px;
  background: transparent;
  z-index: 5;
}

.bm-burger-custom-button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.bm-burger-custom-button-mini {
  display: none;
  position: relative;
  background: transparent;
  cursor: pointer;
}

.bm-burger-bars {
  background: var(--black-main);
}

.bm-menu-wrap {
  position: fixed;
  top: 0px;
  right: 0px;
}

.bm-cross-button {
  opacity: 0;
}

.bm-menu {
  background: rgba(38, 38, 38, 1);
  overflow: unset;
  position: relative;
  /* padding: 2.5em 1.5em 0; */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(0, 70px));
}

/* Individual item */
.bm-item {
  /* width: 100%; */
  height: 100%;
  font-size: 1.5em;
  /* font-weight: bold; */
  text-decoration: none;
  align-self: center;
  text-align: center;
  color: var(--white-main);
  display: grid;
  align-items: center;
}

/* .bm-item:hover { */
/*   color: #fff100; */
/*   background-color: var(--button-hover-bg-color); */
/* } */

.header-link {
  display: none;
}

.nav-login {
  padding: 1.5em 2em;
  color: var(--black-main);
  line-height: 1;
  cursor: pointer;
  transition: color 0.2s;
  width: 100%;
  text-align: center;
}

.nav-login:hover {
  color: rgba(38, 38, 38, 0.7);
}

@media (min-width: 993px) {}

@media (min-width: 769px) and (max-width: 992px) {}

@media (max-width: 768px) {
  .header-link {
    display: grid;
  }
}

@media (max-width: 576px) {
  .bm-burger-custom-button {
    display: none;
  }

  .nav-login {
    display: none;
  }

  .bm-burger-custom-button-mini {
    display: grid;
    height: 50px;
  }

  /* .bm-burger-button { */
  /*   display: grid; */
  /*   position: relative; */
  /*   /* height: 100%; */
  /*   /* width: 100%; */
  /* } */
}
