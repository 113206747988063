.player-container {
  width: 100%;
}

/* .player-close-button { */
/* } */
.rhap_mini-player {
  display: none;
}

.rhap_container {
  box-sizing: border-box;
  display: grid;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  background-color: var(--button-bg-color);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.rhap_container button:hover {
  color: var(--green);
}

.rhap_container:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_container svg {
  vertical-align: initial;
}

.rhap_progress-container {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

/**/
.rhap_progress-container:focus:not(:focus-visible) {
  outline: 0;
}

/**/
.rhap_time {
  /* color: #333; */
  color: var(--green);
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
}

/**/
.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: #dddddd;
  border-radius: 2px;
}

/**/
.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: var(--green);
  border-radius: 2px;
}

/**/
.rhap_progress-bar-show-download {
  background-color: rgba(221, 221, 221, 0.5);
}

/**/
.rhap_download-progress {
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #dddddd;
  border-radius: 2px;
}

/**/
.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  top: -8px;
  background: var(--green);
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

.rhap_controls-section {
  display: grid;
  padding: 10px 15px;
  grid-template-columns: auto auto auto min-content min-content min-content 1fr auto auto auto;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.rhap_controls-section:nth-child(5) {
  overflow: hidden;
}

.rhap_song_name {
  text-align: center;
  overflow: hidden;
}

/**/
.rhap_additional-controls {
  width: 30px;
  display: grid;
  align-items: center;
}

.rhap_repeat-button {
  color: var(--green) !important;
}

/**/
.rhap_repeat-button {
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #868686;
  margin-right: 6px;
}

/**/
.rhap_main-controls {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
}

/**/
.rhap_main-controls-button {
  cursor: pointer;
  margin: 0 3px;
  color: #868686;
  font-size: 35px;
  width: 35px;
  height: 35px;
}

.rhap_main-controls-button img {
  width: 100%;
}

/**/
.rhap_play-pause-button {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

/**/
.rhap_volume-button {
  flex: 0 0 26px;
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #868686;
  margin-right: 6px;
}

/**/
.rhap_volume-container {
  display: grid;
  grid-template-columns: auto minmax(20px, 60px);
  justify-content: end;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
}

/**/
.rhap_volume-bar-area {
  width: 100%;
  min-width: 30px;
  cursor: pointer;
}

/**/
.rhap_volume-bar-area:focus:not(:focus-visible) {
  outline: 0;
}

/**/
.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  background: #dddddd;
  border-radius: 2px;
}

/**/
.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  left: 0;
  top: -4px;
  background: var(--green);
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
  cursor: pointer;
}

/**/
.rhap_volume-indicator:hover {
  opacity: 0.9;
}

/**/
.rhap_volume-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: var(--green);
  border-radius: 2px;
}

.rhap_button-clear {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}

.rhap_main-play-list-button {
  /* line-height: 1; */

  cursor: pointer;
  margin: 0 3px;
  font-size: 35px;
  width: 35px;
  height: 35px;
  display: grid;
  align-items: center;
}

.rhap_main-play-list-button img {
  width: 100%;
}

/**/
.rhap_button-clear:hover {
  opacity: 0.9;
  transition-duration: 0.2s;
}

/**/
.rhap_button-clear:active {
  opacity: 0.95;
}

.rhap_button-clear:focus:not(:focus-visible) {
  outline: 0;
}

.test_sercle-container {
  display: none;
}

.rhap_additional-controls {
  cursor: pointer;
}

@media (max-width: 576px) {
  .rhap_mini-player {
    display: block;
  }

  .rhap_container {
    /* display: none; */
  }

  .rhap_controls-section {
    /* grid-template-columns: auto auto auto auto auto auto auto; */
    grid-template-areas: "current-time name name name total-time";
    /* "additional-controls prev play next playlist"; */
    grid-template-columns: auto 1fr auto 1fr auto;
  }

  .test_sercle-container {
    display: block;
    height: 250px;
    width: 250px;
    background-color: var(--black-main);
    border: 1px solid var(--yelow-main);
    border-radius: 50%;
    position: fixed;
    top: 50%;
    right: -110px;
    /* display: grid; */
    /* grid-template-rows: 1fr; */
    /* grid-template-columns: 1fr; */
  }

  .sercle_control-section {
    position: absolute;
    /* z-index: 2; */
    width: 140px;
    height: 250px;
    /* max-width: 140px; */
    top: 0;
    right: 110px;
    display: grid;
    grid-template-areas:
      "prev prev"
      "additional-controls play"
      "playlist play"
      "next next";
    /* grid-template-columns: min-content min-content; */
    grid-template-rows: repeat(4, 1fr);
  }

  .rhap_main-play-list-button {
    grid-area: playlist;
    display: none;
  }

  .rhap_current-time {
    grid-area: current-time;
  }

  .rhap_song_name {
    overflow: hidden;
    grid-area: name;
  }

  .rhap_total-time {
    grid-area: total-time;
  }

  .rhap_play-pause-button {
    grid-area: play;
    display: none;
  }

  .rhap_main-controls-button {
    display: none;
  }

  .sercle_control-section:nth-child(1) {
    grid-area: prev;
    display: none;
    justify-self: self-end;
    align-self: self-end;
  }

  .sercle_control-section:nth-child(3) {
    grid-area: next;
    justify-self: self-end;
    align-self: self-end;
    display: none;
  }

  .rhap_additional-controls {
    display: none;
  }

  .sercle_control-section .rhap_main-play-list-button {
    grid-area: playlist;
    width: 30px;
    display: grid;
    align-items: center;
  }

  .rhap_additional-controls {
    grid-area: additional-controls;
  }

  .time-sep {
    display: none;
  }

  .rhap_volume-container {
    display: none;
  }
}
