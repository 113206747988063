.appHeader {
  position: relative;
  height: 4.75rem;
  width: 100%;
  display: grid;
  grid-template-columns: auto 5fr 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-content: center;
  /* align-items: center; */
  /* justify-items: center; */
  background-color: var(--secondary-bg-color);
  background-position: 50%;
  background-size: cover;
}

.appHeader::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.25rem;
  background-image: linear-gradient(90deg,
      #ff00f0 17.5%,
      #fff100 17.5% 35.6%,
      #00ffa2 35.6% 100%);
}

.appHeader a {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
}

.appHeader a:hover {
  color: var(--green);
}

.searchBarWraper {
  align-self: center;
  width: 90%;
  height: 50%;
}

.navList {
  justify-self: start;
  flex-grow: 1;
  display: flex;
  align-self: center;
  /* margin-left: 3.75rem; */
}

.navItemActive {
  color: var(--yelow-main);
  position: relative;
  padding: 1.5em 2.1875rem;
  font-size: 1rem;
  /* font-family: UDM, SourceHanSans-Medium; */
  line-height: 1;
  transition: color 0.3s ease;
  cursor: pointer;
}

.navItem {
  color: var(--white-main);
  position: relative;
  padding: 1.5em 2.1875rem;
  font-size: 1rem;
  /* font-family: UDM, SourceHanSans-Medium; */
  line-height: 1;
  transition: color 0.3s ease;
  cursor: pointer;
}

.navRight {
  justify-self: self-end;
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;
  color: #121212;
  background-color: var(--yelow-main);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.navRight a {
  text-decoration: none;
  font-weight: normal;
}

/* .navRight:hover a { */
/*   color: var(--black-main); */
/* } */

.multiselect {
  color: #262626;
  background-color: inherit;
}

.active {
  color: var(--yelow-main);
}

@media (min-width: 577px) and (max-width: 768px) {
  .appHeader {
    grid-template-columns: 1fr auto;
  }

  .navList {
    display: none;
  }

  .searchBarWraper {
    justify-self: center;
  }
}

@media (max-width: 576px) {
  .appHeader {
    grid-template-columns: 1fr 80px;
  }

  .navList {
    display: none;
  }

  .searchBarWraper {
    justify-self: center;
  }

  .navRight {
    position: relative;
    height: 100%;
    background-color: transparent;
  }
}
