.circleContainer {
  display: block;
  height: 250px;
  width: 250px;
  /* background-color: var(--black-main); */
  /* border: 1px solid var(--yelow-main); */
  border-radius: 50%;
  position: fixed;
  top: 50%;
  right: -110px;
}

.bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 208px;
  width: 208px;
  /* background-color: yellow; */
  background-color: var(--black-main);
  border-radius: 50%;
}

.expand {
  position: fixed;
  height: 40px;
  width: 40px;
  top: 500px;
  right: 0px;
}

.circle {
  position: absolute;
  z-index: 2;
}

.sercleControlSection {
  position: absolute;
  top: 50%;
  right: 50%;
  display: grid;
  justify-content: end;
  grid-template-areas:
    "additional-controls prev"
    "additional-controls play"
    "playlist            play"
    "playlist            next";
  grid-template-columns: min-content min-content;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-rows: repeat(min-content);
}

.prev {
  cursor: pointer;
  grid-area: prev;
  width: 35px;
  height: 35px;
  align-self: self-end;
  justify-self: self-end;
  transform: rotate(90deg);
}

.playPauseButton {
  cursor: pointer;
  grid-area: play;
  width: 40px;
  height: 40px;
  align-self: center;
  justify-self: self-end;
}

.next {
  cursor: pointer;
  grid-area: next;
  width: 35px;
  height: 35px;
  align-self: self-start;
  justify-self: self-end;
  transform: rotate(90deg);
}

.playListButton {
  cursor: pointer;
  grid-area: playlist;
  width: 35px;
  height: 35px;
  align-self: self-start;
  justify-self: self-end;
}

.playListButton img {
  width: 100%;
}

.additionalControls {
  cursor: pointer;
  grid-area: additional-controls;
  width: 35px;
  height: 35px;
  align-self: self-end;
  justify-self: self-end;
}
