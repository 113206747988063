.songContainer {
  text-align: left;
  background-color: var(--black-main);
  display: grid;
  grid-template-areas:
    "play title menu"
    "play artists menu"
    "play tags menu";
  grid-template-columns: min-content 1fr;
  transition: 0.6s;
  border-radius: 10px;
  padding: 3px;
  font-size: 16px;
}

.songContainer:hover {
  background-color: var(--button-hover-bg-color);
  transition: 0.6s;
}

.playButton {
  grid-area: play;
  align-self: center;
  justify-self: center;
  min-width: 40px;
  height: 40px;
  opacity: 0;
}

.songContainer:hover .playButton {
  opacity: 1;
  z-index: 1;
}

.songNumber {
  grid-area: play;
  text-align: center;
  align-self: center;
  justify-self: center;
  width: 40px;
}

.songContainer:hover .songNumber {
  opacity: 0;
  z-index: 0;
}

.title {
  color: var(--green);
  display: block;
  grid-area: title;
  font-size: 18px;
}

.artists {
  grid-area: artists;
}

.tags {
  grid-area: tags;
}

.menu {
  grid-area: menu;
  justify-self: center;
  align-self: center;
}

@media (max-width: 576px) {
  .songContainer {
    grid-template-areas:
      "num play title menu"
      "num play artists menu"
      "num play tags menu";
    grid-template-columns: min-content min-content auto auto;
  }

  .songContainer:hover .songNumber {
    opacity: 1;
  }

  .menu {
    justify-self: self-end;
  }

  .playButton {
    min-width: 50px;
    height: 100%;
    opacity: 1;
  }

  .songNumber {
    grid-area: num;
    justify-self: center;
    align-self: center;
    text-align: center;
    width: 100%;
  }
}
