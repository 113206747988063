.songContainer {
  position: relative;
  text-align: left;
  /* background-color: var(--black-main); */
  display: grid;
  grid-template-areas:
    "play title  title edit"
    "play artists artists artists"
    "play tags tags tags"
    "play confirm confirm confirm";
  grid-template-columns: min-content 1fr min-content min-content;
  grid-template-rows: min-content min-content min-content;
  row-gap: 5px;
  border-radius: 10px;
  padding: 3px;
  transition: 0.6s;
}

.playButton {
  grid-area: play;
  align-self: center;
  justify-self: center;
  min-width: 40px;
  height: 40px;
  opacity: 0;
}

.songContainer:hover .playButton {
  opacity: 1;
}

.songNumber {
  grid-area: play;
  text-align: center;
  align-self: center;
  justify-self: center;
  width: 40px;
}

.selected {
  background-color: var(--primary-bg-color);
  transition: 0.6s;
}

.selectedSongIco {
  grid-area: play;
  align-self: center;
  justify-self: center;
  width: 20px;
}

.songContainer:hover .songNumber,
.songContainer:hover .selectedSongIco {
  display: none;
  /* opacity: 0; */
  /* z-index: 0; */
}

.confirm {
  grid-area: confirm;
}

.tags {
  grid-area: tags;
}

.artists {
  grid-area: artists;
}

.editButton {
  min-width: 40px;
  cursor: pointer;
  grid-area: edit;
  align-self: self-start;
  justify-self: center;
}

.songContainer:hover {
  background-color: var(--button-hover-bg-color);
  transition: 0.6s;
  /* color: var(--yelow-main); */
}

.title {
  display: block;
  grid-area: title;
  align-self: self-start;
  justify-self: self-start;
  font-size: 1.2em;
}

@media (max-width: 576px) {
  .playButton {
    min-width: 50px;
    opacity: 1;
  }

  .songContainer:hover .songNumber {
    opacity: 1;
  }

  .songNumber {
    display: none;
  }

  .selectedSongIco {
    display: none;
  }
}
