/* /* Color Theme Swatches in Hex */
/* .AI-powered-Financial-Reporting-&-Management-Platform-1-hex { color: #f2e416; } */
/* .AI-powered-Financial-Reporting-&-Management-Platform-2-hex { color: #D9A404; } */
/* .AI-powered-Financial-Reporting-&-Management-Platform-3-hex { color: #593636; } */
/* .AI-powered-Financial-Reporting-&-Management-Platform-4-hex { color: #D9D9D9; } */
/* .AI-powered-Financial-Reporting-&-Management-Platform-5-hex { color: #262626; } */
/**/
/* /* Color Theme Swatches in RGBA */
/* .AI-powered-Financial-Reporting-&-Management-Platform-1-rgba { color: rgba(242, 227, 21, 1); } */
/* .AI-powered-Financial-Reporting-&-Management-Platform-2-rgba { color: rgba(216, 163, 4, 1); } */
/* .AI-powered-Financial-Reporting-&-Management-Platform-3-rgba { color: rgba(89, 54, 54, 1); } */
/* .AI-powered-Financial-Reporting-&-Management-Platform-4-rgba { color: rgba(216, 216, 216, 1); } */
/* .AI-powered-Financial-Reporting-&-Management-Platform-5-rgba { color: rgba(38, 38, 38, 1); } */

:root {
  --yelow-main: #f2e416;
  --yelow-secondary: #d9a404;
  --green: #00ffa2;
  --black-main: #262626;
  --black-bg-color: rgba(38, 38, 38, 1);
  --white-main: #d9d9d9;
  --primary-bg-color: #333;
  --secondary-bg-color: rgba(51, 51, 51, 0.9);
  --button-bg-color: #333;
  --button-hover-bg-color: #444;
  --button-active-bg-color: #222;
  --link-color: var(--white-main);
  --link-hover-color: var(--yelow-main);
  --input-bg-color: rgba(51, 51, 51, 0.8);
  --input-border-color: #333;
  --input-focus-border-color: #222;
  --column-count: 2;
  --ci-primary-color: #00ffa2;
}

.contexify_theme-dark {
  --contexify-menu-bgColor: rgba(40, 40, 40, 0.98);
  --contexify-separator-color: #4c4c4c;
  --contexify-item-color: var(--white-main);
  --contexify-activeItem-color: var(--white-main);
  --contexify-activeItem-bgColor: #2ec27e;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: var(--white-main);
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: var(--white-main);
}

a {
  text-decoration: none;
  color: var(--white-main);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background-image: url("../public/bg_image.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-size: 18px;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: var(--white-main);
  height: 100%;
}

.Toastify__toast-container--top-left {
  top: 5em !important;
}

.total-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../public/bg_image.jpg");
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.app-container {
  min-width: 100%;
  display: grid;
  min-height: 100%;
  grid-template-rows: auto 1fr auto;
  user-select: text;
  align-items: start;
}

.app {
  scroll-behavior: smooth;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  height: 100%;
}

/* Для WebKit (Chrome, Safari) */
.app::-webkit-scrollbar {
  width: 4px;
  background-color: var(--black-bg-color);
  /* Ширина скроллбара */
}

.app::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Цвет ползунка скроллбара */
}

/* Для Internet Explorer */
.app::-ms-scrollbar {
  width: 4px;
  /* Ширина скроллбара */
}

.app::-ms-scrollbar-thumb {
  background-color: #888;
  /* Цвет ползунка скроллбара */
}

.active {
  color: var(--link-hover-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  background-color: var(--button-bg-color);
  /* background-color: var(--yelow-main); */
  color: var(--white-main);
  /* border: 1px solid var(--yelow-secondary); */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 0 10px #222;
  transition: all 0.3s ease;
}

.button:hover {
  color: #fff100;
  /* background-color: rgba(94, 94, 94, 0.6); */
  background-color: var(--button-hover-bg-color);

  box-shadow: 0 0 15px #222;
}

.button:active {
  background-color: var(--button-active-bg-color);
  box-shadow: 0 0 5px #222;
}

.input {
  background-color: var(--input-bg-color);
  color: var(--white-main);
  border: 1px solid var(--input-border-color);
  padding: 10px;
  outline: none;
  border-radius: 5px;
  max-width: 100%;
}

.input:focus {
  border-color: var(--input-focus-border-color);
}

.about-container {
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--primary-bg-color);
}

.of-link {
  margin-bottom: 20px;
}

.about-container a {
  text-decoration: none;
  font-size: 1.2em;
  color: var(--yelow-main);
}

.centered-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form-container {
  display: grid;
  position: relative;
  text-align: center;
  min-width: 300px;
  max-width: 400px;
  background-color: var(--secondary-bg-color);
  /* width: 100vw; */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.form-group {
  /* width: 100%; */
  /* margin-bottom: 20px; */
  position: relative;
  transition:
    color 0.3s,
    border-color 0.3s,
    background-color 0.3s;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 4px;
  /* background-color: #484848; */
  padding: 0 16px 0 24px;
  height: 66px;
  display: flex;
  align-items: center;
}

.form-group button {
  user-select: none;
  position: relative;
  display: block;
  width: 100%;
  height: 55px;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;
  background-color: var(--yelow-main);
  white-space: nowrap;
}

.form-group input {
  display: flex;
  position: relative;
  transition:
    color 0.3s,
    border-color 0.3s,
    background-color 0.3s;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #484848;
  width: 100%;
  margin-bottom: 10px;
}

.other-auth-options {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auth-option {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: #d4d8dd;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination a {
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: var(--button-bg-color);
  transition: background-color 0.3s;
}

.pagination a:hover {
  background-color: var(--button-hover-bg-color);
  border-color: var(--white-main);
}

.pagination .active a {
  background-color: var(--button-active-bg-color);
}

.pagination .disabled a {
  color: #ddd;
  display: none;
  pointer-events: none;
}

.pagination li {
  height: 100%;
}

.form-changes {
  /* width: 100%; */
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  /* border: 1px solid var(--yelow-secondary); */
  background-color: var(--secondary-bg-color);
  color: var(--white-main);
  border-radius: 5px;
}

.form-changes .field {
  margin-bottom: 10px;
}

.form-changes .label {
  font-size: 16px;
  font-weight: bold;
}

.form-changes textarea {
  width: 100%;
  height: 30px;
  padding: 5px;
}

.from-changes .button {
  width: 100px;
  height: 30px;
  background-color: var(--black-main);
  color: var(--white-main);
  border: 1px solid #cccccc;
  border-radius: 5px;
  cursor: pointer;
}

.disabled {
  background-color: var(--secondary-bg-color);
  cursor: not-allowed;
}

.profile-container {
  background-color: var(--secondary-bg-color);
  padding: 40px;
  border: 2px solid var(--yelow-secondary);
  border-radius: 20px;
  font-size: 18px;
  min-width: 400px;
  min-height: 400px;
}

.offers-page {
  background-color: var(--secondary-bg-color);
  padding: 40px;
  color: var(--white-main);
  min-height: 100%;
}

.offer-item-container {
  border: solid 2px var(--yelow-secondary);
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
}

.offer-item-detail {
  padding: 5px;
}

.offer-click-container div {
  padding: 5px;
}

.offers-navigatin {
  display: grid;
  grid-gap: 1%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 20px;
}

.offers-navigatin .active {
  background-color: var(--white-main);
  color: var(--black-main);
}

.editor-container {
  margin-bottom: 20px;
}

.actions button {
  background-color: var(--white-main);
  color: var(--black-main);
  margin-right: 10px;
  flex: 1;
}

.actions input {
  background-color: var(--white-main);
  color: var(--black-main);
  margin-bottom: 20px;
}

.tags-artrist-admin-page {
  background-color: var(--secondary-bg-color);
  padding: 40px;
  color: var(--white-main);
  height: 100%;
}

.footer-container {
  display: flex;
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--black-bg-color);
  color: var(--white-main);
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-container::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 0.25rem;
  background-image: linear-gradient(
    90deg,
    #ff00f0 17.5%,
    #fff100 17.5% 35.6%,
    #00ffa2 35.6% 100%
  );
}

.footer-link a {
  margin: 0 15px;
  /* Отступы между ссылками (можете изменить под свой дизайн) */
  color: var(--white-main);
  /* Цвет текста ссылок (можете изменить под свой дизайн) */
  text-decoration: none;
  /* Убираем подчеркивание у ссылок */
}

.footer-link:hover {
  text-decoration: underline;
  /* Подчеркиваем ссылки при наведении */
}

.footer-foo {
  height: 65px;
}

.album-page {
  min-height: 100%;
  background-color: var(--black-main);
}

.contextMenu {
}

@media (min-width: 993px) {
}

@media (min-width: 769px) and (max-width: 992px) {
}

@media (min-width: 577px) and (max-width: 768px) {
}

@media (max-width: 576px) {
  .pagination {
    font-size: 14px;
  }

  /* .pagination li:nth-child(4), */
  /* .pagination li:nth-child(5) { */
  /*   display: none; */
  /* } */

  .profile-container {
    /* width: 100%; */

    min-width: 95%;
    /* min-height: 80%; */
  }
}
