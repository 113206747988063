.ListContainer {
  font-size: 1.2em;
}

.shortString {
  display: inline;
  background: linear-gradient(to right,
      var(--white-main),
      var(--black-main) 100%,
      transparent);
  -webkit-background-clip: text;
  color: transparent;
  overflow: hidden;
  max-width: 20%;
}

.ListContainer svg {
  display: none;
  height: 0.7em;
  background: rgba(0, 0, 0, 0);
  border: none;
  color: var(--green);
  cursor: pointer;
}

.ListContainer:hover svg {
  display: inline;
}

@media (max-width: 576px) {
  .ListContainer svg {
    display: inline;
  }
}
