.songContainer {
  text-align: left;
  display: grid;
  border-radius: 10px;
  grid-template-areas:
    "play title menu"
    "play artists menu"
    "play tags menu";
  grid-template-columns: min-content 1fr auto auto;
  column-gap: 10px;
  padding: 3px;
  transition: 0.6s;
}

.songContainer:hover {
  background-color: var(--button-hover-bg-color);
  transition: 0.6s;
}

.selected {
  background-color: var(--primary-bg-color);
  transition: 0.6s;
}

.selected .selectedSongIco {
  opacity: 1;
}

.playButton {
  grid-area: play;
  opacity: 0;
  align-self: center;
  justify-self: center;
  min-width: 40px;
  height: 40px;
}

.songContainer:hover .playButton {
  opacity: 1;
  z-index: 1;
}

.songNumber {
  grid-area: play;
  text-align: center;
  align-self: center;
  justify-self: center;
  width: 40px;
}

.songContainer:hover .songNumber {
  display: none;
}

.selectedSongIco {
  grid-area: play;
  align-self: center;
  justify-self: center;
  width: 20px;
  opacity: 0;
}

.songContainer:hover .songNumber,
.songContainer:hover .selectedSongIco {
  transition: 0.6s;

  display: none;
  /* opacity: 0; */
  /* z-index: 0; */
}

.title {
  display: block;
  grid-area: title;
  color: var(--green);
}

.artists {
  grid-area: artists;
}

.tags {
  grid-area: tags;
}

.menu {
  grid-area: menu;
  align-self: center;
  justify-self: center;
}

@media (max-width: 576px) {
  .playButton {
    min-width: 50px;
    opacity: 1;
  }

  .songNumber {
    display: none;
  }

  .selectedSongIco {
    display: none;
  }
}
