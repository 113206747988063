.Modal {
  z-index: 10000;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* height: 100vh; */
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
}

.Modal.active {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.ModalContent {
  display: block;
  padding: 20px;
  width: 80%;
  max-width: 1200px;
}

@media (max-width: 576px) {
  .ModalContent {
    width: 100%;
    padding: 0px;
  }
}
